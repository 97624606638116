.ag-header-cell-label {
  justify-content: center;
}

.dark-glass {
  background-color: rgba(0,0,0,0.2);
  background-repeat: no-repeat;
  background-size: cover;
  backdrop-filter: blur(10px);
}
