:root {
   --plan-1: violet;
   --plan-2: aqua;
   --plan-3: gold;
   --plan-4: rgb(197, 192, 192);
   --plan-5: rgb(161, 239, 245);
   --plan-6: rgb(191, 252, 164);

   --list-card-bg: #EEEEFF;
   --list-card-s0: #F8F8F8;
   --list-card-s1: #1ab7ea;
   --list-card-s2: #0077B5;
   --list-card-s3: #39539E;

   --badge-color: #f9e1e0;
   --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
   --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

body {
   font-family: var(--font-family-sans-serif);
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   font-size: small;
}

.logo-shadow {
   text-shadow: 1px 1px 5px white;
}

.main-font {
   font-size: small;
}

.main-panel {
   height: calc(100vh - 58px); /* 78px with status bar*/
}

.side-panel {
   height: calc(100vh - 58px - 50px);
}

.tog-btn {
   box-shadow: 0 3px 4px 0 rgba(0,0,0,0.10);
}

.tog-btn:hover {
   box-shadow: 0 6px 8px 0 rgba(0,0,0,0.20);
}
/*
.btn:after {
   background: none;
   font-size: x-large;
}

.btn-shrink:after {
   // content: "\1F844";
   content: '\2BC7';
}

.btn-expand:after {
   // content: "\1F846";
   content: '\2BC8';
}

.btn-expand:hover,
.btn-shrink:hover {
   background: none;
   transform: rotate(120deg);
   transition: all 0.25s ease-in-out;
   // animation: shake 0.5s;
   // animation-iteration-count: infinite;
}

@keyframes shake {
   0% { transform: translate(3px, 0px) rotate(0deg); }
   10% { transform: translate(1px, 0px) rotate(0deg); }
   20% { transform: translate(0px, 0px) rotate(0deg); }
   30% { transform: translate(-1px, 0px) rotate(0deg); }
   40% { transform: translate(-3px, 0px) rotate(0deg); }
   50% { transform: translate(-1px, 0px) rotate(0deg); }
   60% { transform: translate(3px, 0px) rotate(0deg); }
   70% { transform: translate(1px, 0px) rotate(0deg); }
   80% { transform: translate(-1px, 0px) rotate(0deg); }
   90% { transform: translate(1px, 0px) rotate(0deg); }
   100% { transform: translate(1px, 0px) rotate(0deg); }
 }
*/

.badge-count {
   position: absolute;
   top: -20px;
   right: -10px;
   color: black;
   background-color: var(--badge-color);
   border-radius: 8px;
   box-shadow: 0 3px 4px 0 rgba(0,0,0,0.25);
   padding: 4px 8px;
   font-size: 12px;
 }

 .flipcard {
   background-color: transparent;
   perspective: 1000px;
}

.flipcard-front, .flipcard-back {
   -webkit-backface-visibility: hidden;
   backface-visibility: hidden;
}

.flipcard-front {
   background-color: white;
}

.flipcard-back {
   display: none;
   transform: rotateY(180deg);
}

.flipcard-inner {
   height: 210px;
   margin-bottom: 5px;
   transition: transform 0.6s;
   transform-style: preserve-3d;
}

.flipcard:hover .flipcard-inner {
   transform: rotateY(180deg);
}

.flipcard:hover .flipcard-front {
   display: none;
}

.flipcard:hover .flipcard-back {
   display: block;
}

.apexcharts-tooltip {
   overflow: visible !important;
   white-space: break-spaces !important;
 }
 .loader-container {
   text-align: center;
   margin-top: 20px; /* Adjust as needed */
 }
 
 .loader-image {
   width: 150px; /* Adjust size as needed */
   height: 150px; /* Adjust size as needed */
 }
 .loader-overlay {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: rgba(255, 255, 255, 0.8);
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 1000;
 }

 .my-modal{ 
   width: 90vw !important;
   max-width:none !important
}