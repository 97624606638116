// Your variable overrides
// $primary: olivedrab;
$success: #18A558;
$warning: #D27D2D; // #CDA67A;
$danger: #880808; // #FF4500;
$gradient: radial-gradient(circle, rgba(white, .25), rgba(white, .15), rgba(white, 0));

// Bootstrap and its default variables
@import "node_modules/bootstrap/scss/bootstrap";

.nav-tabs .nav-item .nav-link.active {
    background-color: $primary;
    color: white;
  }
  